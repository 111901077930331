/* body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
}

.container {
  display: flex;
  max-width: 1000px;
  margin: 50px auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.order-summary {
  width: 50%;
  background-color: #000;
  color: white;
  padding: 20px;
}

.order-summary h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.order-summary h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.order-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.text-small {
  font-size: 0.75rem;
}

.editable-input {
  border: none;
  background-color: transparent;
  font-size: 16px;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantity-controls button {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.quantity-controls span {
  font-size: 0.75rem;
}

.add-product-btn {
  margin-top: 15px;
  padding: 5px 10px;
}

.item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.item-details {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 10px;
}

.item-price {
  font-size: 18px;
}

.payment-form {
  width: 50%;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem; 

.apple-pay-btn {
  background-color: black;
  color: white;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.divider {
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
  color: #888;
}

form input, form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.card-info {
  display: flex;
  flex-direction: column; 
  gap: 0;
}

.card-number input {
  width: 100%; 
  gap: 0;
  margin-bottom : 0;
}

.card-details {
  display: flex;
  gap: 0; 
  margin-top: 0;
}

.card-details input {
  flex: 1;
}
.card-details input:first-child {
  border-right: none; 
}

.pay-btn {
  background-color: black;
  color: white;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.pay-btn:hover {
  background-color: #333;
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user-info input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.user-info input:focus {
  border-color: #000;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.powered-by {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}

.powered-by img {
  width: 80px;
  margin-left: 5px;
} */
/* 

.progress-container {
  display: flex;
  align-items: center; 
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
}

.progress-container::before {
  content: "";
  background-color: var(--line-border-empty);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  z-index: -1;
}

.progress {
  background-color: rgb(159, 158, 158) !important;
  position: absolute;
  top: 28%;
  left: 8%;
  transform: translateY(-50%);
  height: 2px !important;
  width: 0%;
  z-index: -1;
  transition: 0.4s ease;
  z-index: 1;
}

.progress.active {
  background-color: rgb(0, 0, 0) !important;
  position: absolute;
  top: 28%;
  left: 8%;
  transform: translateY(-50%);
  height: 2px !important;
  width: 0%;
  z-index: -1;
  transition: 0.4s ease;
  z-index: 1;
}

.steps {
  display: flex; 
  justify-content: space-between; 
  width: 100%; 
  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.circle {
  background-color: #fff;
  color: #999;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--line-border-empty);
  transition: 0.4s ease;
  z-index: 9;
}

.step .label {
  margin-top: 8px;
  font-size: 14px;
  color: #fff;
}

.circle.active {
  border-color: var(--line-border-fill);
}

.custome-counter {
  height: 25vh;
  border-radius: 15px;
  padding: 20px;
  background-color: rgb(32, 32, 228);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card {
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
@media only scree and (max-width: 576px) {
  .custome-counter {
    width: 100% !important;
  }
}
   */


/* General Reset */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

/* 
.step-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e3cff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

.step-number {
  background-color: white;
  color: #1e3cff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
}

.step-label {
  margin-top: 10px;
  color: white;
  font-size: 14px;
}
.form-container {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 500px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

input[type="text"] {
  width: 48%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.btn.prev {
  background-color: #777;
  color: white;
  margin-right: 10px;
}

.btn.next {
  background-color: #007bff;
  color: white;
}

.progress-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 10px;
  height: 10px; 
  margin-bottom: 20px;
}

.progress {
  height: 100%;
  background-color: #1e3cff; 
  border-radius: 10px;
}  */
/* 

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e3cff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

.step-number {
  background-color: white;
  color: #1e3cff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
}

.step-label {
  margin-top: 10px;
  color: white;
  font-size: 14px;
}

.step.active .step-number {
  background-color: #007bff;
  color: white;
}

.form-container {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 500px;
  position: relative;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

input[type="text"], input[type="email"], input[type="password"] {
  width: 48%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.btn.prev {
  background-color: #777;
  color: white;
  margin-right: 10px;
}

.btn.next {
  background-color: #007bff;
  color: white;
}

.btn.submit {
  background-color: #28a745;
  color: white;
}
.step-progress {
  position: absolute;
  top: 50px;
  width: 100%;
} */



.progress-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
}

.progress-container::before {
  content: "";
  background-color: var(--line-border-empty);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  z-index: -1;
}

.progress {
  background-color: rgb(159, 158, 158);
  position: absolute;
  top: 28%;
  left: 8%;
  transform: translateY(-50%);
  height: 2px;
  width: 0%;
  z-index: 1;
  transition: 0.4s ease;
}

.steps {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.circle {
  background-color: #fff;
  color: #999;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--line-border-empty);
  transition: 0.4s ease;
  z-index: 9;
}

.step .label {
  margin-top: 8px;
  font-size: 14px;
  color: #fff;
}

.circle.active {
  border-color: var(--line-border-fill);
}

.custome-counter {
  height: 20vh;
  border-radius: 15px;
  padding: 20px;
  background-color: rgb(32, 32, 228);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card {
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

@media only screen and (max-width: 576px) {
  .custome-counter {
    width: 100% !important;
  }
}
